import antd from 'ant-design-vue/lib/locale-provider/id_ID'
import validation from 'vee-validate/dist/locale/id.json'

const messages = {
  language: 'Bahasa',
  topBar: {
    profileMenu: {
      name: 'Nama',
      email: 'Email',
      phone: 'Telepon',
      logout: 'Keluar',
      account_setting: 'Pengaturan Akun',
      switch_to: 'Beralih ke',
    },
    subscribeNow: 'Berlangganan',
    no_Business: 'Bisnis tidak ditemukan!',
    no_Warehouse: 'Gudang tidak ditemukan!',
    search_Business: 'Cari bisnis',
    search_Warehouse: 'Cari gudang',
    further_data: 'Cari data lebih lanjut.',
    total_Business: '{value} bisnis',
    total_Warehouse: '{value} gudang',
    all_: 'Semua',
    all_Business: 'Semua Bisnis',
    all_Warehouse: 'Semua Gudang',
    all_Channel: 'Semua Channel',
  },

  snackBar: {
    notYetConnected: 'Akun anda belum tersambung dengan toko yang anda miliki',
    storeConnected: 'Akun anda tersambung dengan',
    store: 'Toko',
    ConnectToYourStore: 'Sambungkan ke Toko',
  },

  auth: {
    login: 'Masuk',
    register: 'Daftar',
    notRegister: 'Belum memiliki akun?',
    signupNow: 'Daftar Sekarang',
    emailPhoneLabel: 'Email atau No. Handphone',
    emailAndPhoneBlank: 'Email atau No Handpone tidak boleh kosong',
    password: 'Kata Sandi',
    invalidPassword: 'Kata Sandi Tidak Valid',
    confirmPassword: 'Konfirmasi Kata Sandi',
    forgetPassword: 'Lupa Kata Sandi?',
    resetPass: 'Atur ulang kata sandi',
    requiredEmail: 'Email Wajib diisi dengan Benar',
    goToSignIn: 'Ke Halaman Login',
    name: 'Nama',
    businessName: 'Nama Usaha',
    anotherRegister: ' Coba cara lain untuk Daftar ',
    anotherSign: ' Coba cara lain untuk Masuk ',
    passwordNotMatch: 'Konfirmasi kata sandi tidak sesuai',
    requiredName: 'Nama tidak boleh kosong',
    minimumLengthPassword: 'Kata sandi minimal 6 karakter',
    atLeastOneUppercase: 'Kata sandi minimal mempunyai 1 huruf kapital',
    atLeastOneNumber: 'Kata sandi minimal menpunyai 1 angka',
    atLeastOneSpecialChar: 'Kata sandi minimal mempunya 1 karakter spesial',
    verifyAccount: 'Harap lakukan verifikasi',
    sendOtpTo: 'Kami telah mengirimkan Kode OTP ke',
    resendOtp: 'Kirim ulang OTP',
    successVerification: 'Berhasil Terverifikasi',
    userNotRegistered: 'User Belum Terdaftar',
    goToDashboard: 'Lihat Dashboard',
    goToLogin: 'Kembali ke Login',
    needVerification: 'Harap lakukan verifikasi',
    changeEmail: 'Ubah Email',
    emailAddress: 'Alamat email',
    emailExpired: 'Ups, ada masalah dengan link Anda',
    expiredDescription:
      'Sepertinya link Anda telah kedaluwarsa atau tidak disalin dengan benar. Silahkan periksa dan coba lagi atau minta teman Anda untuk mengirimkan ulang undangan.',
    resendEmail: 'Kirim ulang email verifikasi ke:',
    resetMyPass: 'Atur Ulang Kata Sandi',
    sendAgain: 'Kirim ulang',
    invalidEmailPhoneNumber: 'Email atau nomer handphone tidak valid',
    successRegister: 'Registrasi Berhasil',
    loginFailed: 'Tidak Berhasil Masuk',
    hasAccount: 'Sudah memiliki akun?',
    loginHere: 'Masuk Disini',
    signUpWithGoogle: 'Daftar dengan akun Google',
    signUpWithFacebook: 'Daftar dengan akun Facebook',
    signInWithGoogle: 'Masuk dengan akun Google',
    signInWithFacebook: 'Masuk dengan akun Facebook',
  },
  authForm: {
    systemBusy: 'System sibuk, cobalah beberapa saat lagi atau kontak administrator!',
  },
  notification: {
    successAddDiscount: 'Add Discount Voucher Success',
    successDeleteVoucher: 'Delete Voucher Success',
    duplicateVoucherCode: 'Voucher code already exist',
    failedSearchSalesOrder: 'Please select parameter for search sales order',
    failedSearchVoucher: 'Please select parameter for search voucher',
  },
  sideBar: {
    dashboard: 'Beranda',
    sales: 'Pesanan',
    fulfillment: 'Fulfillment',
    product: 'Produk',
    product_master: 'Produk Utama',
    productCategory: 'Kategori Produk',
    management_category: 'Manajemen Kategori',
    price_setting: 'Setting Harga',
    global_variable: 'Variabel Global',
    formula_variable: 'Variabel Formula',
    product_variable: 'Variabel Produk',
    price_formula: 'Formula Harga',
    inventory: 'Inventori',
    stock: 'Persediaan',
    stock_alocation: 'Alokasi Stok',
    inventory_list: 'Daftar Persediaan',
    inventory_movement: 'Riwayat Persediaan',
    setup_stock: 'Pengaturan Stok',
    transfer_stock: 'Transfer Stok',
    promotion: 'Promosi',
    customer: 'Pelanggan',
    channel: 'Saluran',
    analytic: 'Analitik',
    finance: 'Finansial',
    user: 'Pengguna',
    account: 'Akun',
    user_management: 'Manajemen Pengguna',
    setting: 'Pengaturan',
    integration: 'Integrasi',
    business: 'Bisnis',
    // warehouse: 'Pergudangan',
    warehouse: 'Gudang',
    shipping: 'Pengiriman',
    customer_setting: 'Pelanggan',
    sales_setting: 'Pengaturan Pesanan',
    account_setting: 'Pengaturan Akun',
    distributorship: 'Distributorship',
    sales_order: 'Daftar Pesanan',
    product_list: 'Daftar Produk',
    distributorship_management: 'Kelola Distributor',
    store_management: 'Manajemen Toko',
    store_setting: 'Pengaturan Toko',
    price_group_setting: 'Atur Grup Harga',
    export_history: 'Riwayat Ekspor',
    add_store: 'Tambah Toko',
    add_warehouse: 'Tambah Gudang',
    type_shop_name: 'Ketik nama toko',
    type_warehouse_name: 'Ketik Nama Gudang',
    no_shop: 'Belum ada list toko!',
    reseller: 'Reseller',
    resellerdetail: 'Detail Reseller',
    voucher: 'Voucher',
    voucherdetail: 'Detail Voucher',
    addvoucher: 'Tambah Voucher',
    editvoucher: 'Edit Voucher',
    storesetting: 'Store Settings',
    adjustment: 'Penyesuaian Stok',
    'goods-receipt': 'Penerimaan Barang',
    // product_category: "Kategori Produk",
    distributor: 'Distributor',
    distributor_list: 'Daftar Distributor',
    customer_category: 'Kategori Pelanggan',
    discount: 'Diskon',
    approval: 'Approval',
    withdrawal: 'Request Penarikan Dana',
    payment_channel: 'List Bukti Transfer',
    payment_history: 'Riwayat Pembayaran',
    invoice: 'Invoice',
    retur: 'Retur',
    'retur-request-list': 'List Pengajuan Retur',
    'retur-list': 'Retur List',
    web_management: 'Web Management',
    banner: 'Banner',
    redeem: 'Loyalty',
    redeem_sales_channel: 'Redeem',
    redeem_product_channel: 'Produk',
    liststore: 'Daftar Toko',
    product_collection: 'Produk Pilihan',
    // "redeem-setting": "Pengaturan Redeem",
    'credit-notes': 'Credit Notes',
    'customer-list': 'Daftar Pelanggan',
    'price-area': 'Harga per Area',
    'product-list': 'Daftar Produk',
    do: 'Delivery Order',
    po_and_supplier: 'Pembelian',
    purchase_order: 'Atur Pembelian',
    supplier: 'Atur Supplier',
  },
  settingsStore: {
    logo: 'Logo',
    banner: 'Home Banner',
    categoryBanner: 'Category Banner',
    resellerBanner: 'Reseller Banner',
    uploadImage: 'Upload Image',
  },
  dashboardContent: {
    orderStatus: 'Status Pesanan',
    orderStatusDescription:
      'Ringkasan status pesanan saat ini yang perlu ditindaklanjuti',
    orderStatusNew: 'Baru',
    orderStatusConfirmed: 'Terkonfirmasi',
    orderStatusSent: 'Terkirim',
    orderStatusSuccess: 'Berhasil',
    orderStatusCancel: 'Batal',
    summaryItemInventory: 'Inventory',
    summaryBusiness: 'Bisnis',
    summaryClient: 'Klien',
    summaryPO: 'PO Tertunda',
    summaryItemNoStock: 'Stok Habis',
    summarySalesToday: 'Penjualan Hari Ini',
    summaryLogisticToday: 'Logistik Keluar Hari Ini',
    summaryItemSoldToday: 'Item Terjual Hari Ini',
    summarySales: 'Penjualan',
    summaryLogistic: 'Logistik Keluar',
    summaryItemSold: 'Item Terjual',
    paramToday: 'Hari Ini',
    paramLast7day: '7 Hari Terakhir',
    paramLast30day: '30 Hari Terakhir',
    paramThisMonth: 'Bulan Ini',
    paramLastYear: '1 Tahun Terakhir',
    paramThisYear: 'Tahun Ini',
    salesOverview: 'Ikhtisar Penjualan',
    section_description_inventory: 'Total item di Inventory dari Semua Brand',
    section_description_business: 'Total item di Bisnis dari Semua Saluran',
    section_description_client: 'Klien-klien yang bekerjasama',
    section_description_PO: 'Total item di PO Tertunda',
    section_description_nostock: 'Total item Stok Kosong',
    section_description_salesToday: 'Total Penjualan hari ini',
    section_description_logisticToday: 'Total item di Logistik Keluar hari ini',
    section_description_itemsoldToday: 'Total item Terjual hari ini',
    section_description_sales: 'Total item dalam Penjualan',
    section_description_logistic: 'Total item di Logistik Keluar',
    section_description_itemsold: 'Total item Terjual',
    top_region: 'Penjualan Tertinggi',
    top_region_description: '10 Daerah Teratas dengan Penjualan Terbanyak',
    shipping: 'Pengiriman',
    shipping_description: '10 Kurir Pengiriman Teratas',
    channel: 'Channel',
    channel_description: 'Ringkasan Channel',
    business: 'Bisnis',
    business_description: 'Ringkasan Bisnis',
    warehouse: 'Gudang',
    courier: 'Logistik',
    payment: 'Pembayaran',
    payment_method: 'Metode Pembayaran',
    payment_description: 'Ringkasan Pembayaran',
    no_data: 'Tidak ada data',
    start_date: 'Tanggal Mulai',
    end_date: 'Tanggal Akhir',
    today: 'Hari ini',
    is_today: 'hari ini',
    not_today: '',
    today_month: 'Bulan ini',
    today_year: 'Tahun ini',
    last_week: '7 Hari Terakhir',
    last_month: '30 Hari Terakhir',
    last_year: '1 Tahun Terakhir',
    transaction_status: 'Status Transaksi',
    detail_status: 'Ringkasan status transaksi delivery order sampai hari ini yang perlu di tindaklanjuti',
    today_summary: 'Ringkasan Hari Ini',
    sales: 'Sales',
    logistic: 'Logistik',
    logistic_expenses: 'Pengeluaran Logistik',
    item_sold: 'Item Terjual',
    report: 'Laporan',
    date_difference: 'Tanggal akhir min. 7 hari dari tanggal awal',
    sales_overview_graph: 'Grafik Ikhtisar Penjualan',
    compare: 'Bandingkan',
    detail_channel: 'List 10 channel teratas berdasarkan transaksi',
    province: 'Provinsi',
    detail_province: 'List 10 provinsi teratas berdasarkan jumlah order',
    city: 'Kota / Kabupaten',
    detail_city: 'List 10 kota / kabupaten teratas berdasarkan jumlah order',
    product: 'Produk Terlaris',
    detail_product: 'List 10 produk teratas berdasarkan qty',
    catalog: 'Item Terlaris',
    detail_catalog: 'List 10 item teratas berdasarkan qty',
    shopee_id: 'Shopee',
    tokopedia_id: 'Tokopedia',
    tiktok: 'Tiktok',
    tiktok_id: 'Tiktok',
    blibli_id: 'Blibli',
    lazada_id: 'Lazada',
    distributor: 'Distributorship',
    store: 'Webcommerce',
    total: {
      sku: 'Total item dalam gudang',
      oos: 'Total item kosong dalam gudang',
      sales: 'Total',
      canceled_sales: 'Total dibatalkan',
      logistic_expenses: 'Total biaya pengiriman',
      item_sold: 'Total item terjual',
    },
  },
  add_store: {
    channel_integration: 'Integrasi Saluran',
    mandatory_selected_warehouse:
      'Pastikan Anda sudah memilih gudang, kemudian pilih saluran untuk di integrasikan ke toko Anda',
    marketplace_integration: 'Integrasi Marketplace',
    fulfillment_integration: 'Integrasi Fulfilment',
    coming_soon: 'Segera',
    sync_tips: 'Tips Sinkronisasi',
    note_tips:
      'Ketika mulai sinkronisasi Anda akan diarahkan ke halaman {name} untuk login dan otorisasi. Setelah otorisasi berhasil Anda dapat mengatur jasa pengiriman di Pengaturan Jasa Pengiriman Powerbiz.',
    note_tips_tiktok_tokopedia: 'Anda akan melakukan integrasi tiktok shop , proses integrasi ini akan membuat 2 channel yakni tiktok & tokopedia, Pastikan tokopedia anda telah dilakukan binding dengan tiktok anda, namun jika anda belum melakukan binding tersebut maka hanya tiktok saja yang akan terdaftar pada sistem powerbiz. Anda dapat menambahkan tokopedia nantinya ,dengan melakukan daftar toko lalu klik icon “tokopedia” dan pilih “Ya, sudah binding”',
    back: 'Kembali',
    sync_store_sucess: 'Integrasi Toko Berhasil.',
    des_sync_store_sucess:
      'Silakan lakukan sinkronisasi produk pada Daftar Produk Toko Anda dengan mengklik tombol Sync. Produk.',
    add_more_store: 'Tambah Toko Lainnya',
    done_sync: 'Selesai',
    cancel: 'Batal',
    next: 'Selanjutnya',
    choose_chanel: 'Pilih saluran untuk di integrasikan ke toko Anda',
    start_sync: 'Mulai Sinkronisasi',
    complite_detail_information: 'Lengkapi detail informasi berikut',
    select_shop: 'Pilih Toko',
    error_sync: 'Terjadi kesalahan, harap coba lagi',
    complete_information: 'Lengkapi detail informasi berikut',
    sync_warehouse: 'Sinkronisasi Gudang',
    main_warehouse: 'Gudang Utama',
    select_main_warehouse: 'Pilih Gudang Utama',
    channel_warehouse: 'Gudang Channel',
    select_channel_warehouse: 'Pilih Gudang Channel',
  },
  distributor: {
    list_distributor: 'Daftar Distributor',
    search: 'Cari Distributor',
    export_data: 'Export Data',
    add_distributor: 'Tambah Distributor',
    name_distributor: 'Nama Distributor',
    category_distributor: 'Kategori',
    address: 'Alamat',
    action: 'Aksi',
    country: 'Negara',
    city: 'Kota',
    district: 'Kecamatan',
    subdistrict: 'Kecamatan',
    mobile_phone: 'No. Handphone',
    active: 'Aktif',
    inactive: 'Tidak Aktif',
    detail_distributor: 'Detail Distributor',
    active_condition: 'Non Aktifkan',
    inactive_condition: 'Aktifkan',
    deactivate_distributor: '{value} Distributor',
    confirm_deactivate: 'Apakah Anda yakin ingin {value} distributor ?',
    code: 'Kode Distributor',
    name: 'Nama Distributor',
    npwp_id: 'No. NPWP',
    npwp_name: 'Nama NPWP',
    postal_code: 'Kode Pos',
    warehouse_code: 'Kode Gudang',
    warehouse_name: 'Nama Gudang',
    warehouse_address: 'Alamat Gudang',
    use_distributor_address: 'Gunakan Alamat Distributor',
    choose_city: 'Pilih Kota terlebih dahulu',
    choose_district: 'Pilih Kecamatan terlebih dahulu',
    choose_coverage: 'Pilih Kota Coverage Area terlebih dahulu',
    rules: {
      code: 'Kode Distributor tidak boleh kosong',
      name: 'Nama Distributor tidak boleh kosong',
      categories: 'Kategori Produk harus dipilih',
      phone_number: 'No. Handphone tidak boleh kosong',
      taxid: 'No. NPWP tidak boleh kosong',
      taxname: 'Nama NPWP tidak boleh kosong',
      bank_name: 'Nama Bank harus dipilih',
      account_number: 'Nomor Rekening tidak boleh kosong',
      account_name: 'Nama Rekening tidak boleh kosong',
      sla_shipping_day: 'Day SLA Complain tidak boleh kosong',
      city: 'Kota harus dipilih',
      district: 'Kecamatan harus dipilih',
      postal_code: 'Kode Pos tidak boleh kosong',
      address: 'Alamat tidak boleh kosong',
      warehouse_name: 'Nama Gudang tidak boleh kosong',
      coverage: 'Coverage Area harus dipilih',
    },
  },
  reseller: {
    titleHead: 'Reseller',
    search: 'Cari reseller..',
  },
  customer: {
    customer_category: "Kategori Pelanggan",
    list: 'Daftar Pelanggan',
    search: 'Cari Data Pelanggan',
    export: 'Ekspor Data',
    invite: 'Undang Pelanggan',
    name: 'Nama Pelanggan',
    category: 'Kategori Pelanggan',
    email: 'Email',
    mobile: 'No. Handphone',
    distributor: 'Distributor',
    city: 'Kota',
    status: 'Status',
    action: 'Aksi',
    all: 'Semua Status',
    not_approved: 'Belum Disetujui',
    active: 'Aktif',
    inactive: 'Tidak Aktif',
    new: 'Baru',
    approval: 'Persetujuan',
    edit: 'Ubah',
    credit_limit: 'Limit Kredit',
    transaction_limit: 'Limit Transaksi',
    payment_limit: 'Limit Waktu Bayar',
    edit_customer: 'Edit Customer',
    delete: 'Hapus',
    empty_title: 'Belum memiliki data',
    empty_msg: 'Saat ini Anda belum memiliki data',
    add: 'Tambah Pelanggan',
    password: 'Password',
    confirm_password: 'Konfirmasi Password',
    distributor_city: 'Kota Distributor',
    select_category: 'Pilih Kategori Pelanggan',
    select_city: 'Pilih Kota Distributor',
    select_distributor: 'Pilih Distributor',
    select_all: 'Pilih Semua',
    save: 'Simpan',
    cancel: 'Batal',
    approve: 'Disetujui',
    not_approve: 'Tidak Disetujui',
    registration_via: 'Registrasi via Email / No. Handphone',
    shop_code: 'Kode Toko',
    buyer_code: 'Kode Pembeli',
    sales_code: 'Kode Sales',
    store_name: 'Nama Toko',
    store_type: 'Store Type',
    payment_model: 'Model Pembayaran',
    transaction_limits: 'Maksimal Transaksi Kredit',
    coin_usage: 'Penggunaan Koin',
    pay_time_limit: 'Jatuh Tempo',
    setup_credit_limit: 'Setup Kredit Limit',
    user_status: 'Status Pengguna',
    phone: 'Nomor Handphone',
    rules: {
      name: 'Nama Toko harus diisi',
      namecustomer: 'Nama pelanggan harus diisi',
      category: 'Kategori harus dipilih',
      email: 'Email harus diisi',
      email_invalid: 'Email yang diisi tidak valid',
      mobile: 'No. Handphone harus diisi',
      distributor: 'Distributor harus dipilih',
      city: 'Kota distributor harus dipilih',
      password: 'Password harus diisi',
      confirm_password: 'Konfirmasi password harus diisi',
      match_password: 'Konfirmasi password harus sama dengan password',
      sales_code: 'Kode Sales harus dipilih',
      coin_usage: 'Penggunaan koin harus dipilih',
      shop_code: 'Kode Toko harus diisi',
    },
  },
  order: {
    storeName: 'Nama Toko',
    customerDetail: 'Detail Pelanggan',
    namecustomer: 'Customer name is required',
    customerName: 'Nama Pelanggan',
    customerPhone: 'Nomer Telepon Pelanggan',
    customerEmail: 'Email Pelanggan',
    paymentDetail: 'Detail Pembayaran',
    totalPriceOrder: 'Total Belanja',
    paymentStatus: 'Status Pembayaran',
    paymentMethod: 'Metode Pembayaran',
    orderShippingDetail: 'Detail Pengiriman',
    courier: 'Kurir',
    warehouse: 'Gudang',
    detail: 'Detail',
    voucher: 'Voucher',
    shippingCost: 'Biaya Pengiriman',
    itemsSold: 'Item Terjual',
    salesTitle: 'Penjualan',
    cleanFilter: 'Bersihkan Filter',
    totalAllSales: 'Total Semua Sales',
    exportTitle: 'Eksport',
    orderDate: 'Tgl Order',
    business: 'Bisnis',
    channel: 'Channels', // Saluran
    customer: 'Pelanggan',
    orderNumber: 'No Order',
    itemTotal: 'Total Item',
    priceTotal: 'Total Harga',
    productName: 'Nama Produk',
    skuTitle: 'SKU',
    quantityTitle: 'Jml',
    priceTitle: 'Harga',
    discountTilte: 'Diskon',
    totalTitle: 'Sub Total Order',
    paymentsTotalTitle: 'Total Tagihan',
    moreDetailsTitle: 'Lihat Lebih',
    lessDetailsTitle: 'Lihat Sedikit',
    detailsTitle: 'Rincian',
    sortBy: 'Urutkan',
    sortByNewest: 'Terbaru',
    sortByOldest: 'Terlama',
    shippingTitle: 'Detail Pengiriman',
    shippingAddress: 'Alamat Tujuan',
    dropshipperInformation: 'Dikirim sebagai dropshipper',
    shippingCourierTitle: 'Kurir',
    shippingReceiptNumber: 'No Resi',
    shippingLabel: 'Shipping Label',
    shippingWarehouseTitle: 'Dikirim Dari',
    shippingHistoryTitle: 'Riwayat Pengiriman',
    shippingShowMoreDetailsTitle: 'Lihat Lebih Lengkap',
    changesHistory: 'Riwayat perubahan',
    ordersPeriod: 'Periode Pesanan',
    ordersPeriodFrom: 'Periode Pesanan Awal',
    ordersPeriodUntil: 'Periode Pesanan Akhir',
    productCategory: 'Kategori Produk',
    printStatus: 'Status Cetak',
    filterState: {
      all: 'Semua',
      verified: 'Belum dibayar',
      new: 'Baru',
      new_: 'Baru',
      confirmed: 'Terkonfirmasi',
      onprocess: 'Dalam Proses',
      on_process: 'Dalam Proses',
      cancelrequested: 'Konfirmasi pembatalan',
      shipped: 'Pengiriman',
      send: 'Terkirim',
      delivered: 'Terkirim',
      canceled: 'Batal',
      completed: 'Selesai',
      ready_to_ship: 'Siap Dikirim',
      cancel_requested: 'Req Batal',
      // rejected: 'Pembatalan pesanan',
    },
    filterStateTag: {
      all: 'Semua',
      verified: 'Belum dibayar',
      new: 'Baru',
      new_: 'Baru',
      confirmed: 'Terkonfirmasi',
      onprocess: 'Dalam Proses',
      on_process: 'Dalam Proses',
      cancelrequested: 'Konf. pembatalan',
      shipped: 'Pengiriman',
      send: 'Terkirim',
      delivered: 'Terkirim',
      canceled: 'Batal',
      completed: 'Selesai',
      ready_to_ship: 'Siap Dikirim',
      cancel_requested: 'Req Batal',
      // rejected: 'Pembatalan pesanan',
    },
    status: {
      confirmOrder: 'Selesai',
      completedOrder: 'Baru',
    },
    require_order_period: 'Harap isi periode pesanan',
    empty_title: 'Belum memiliki data',
    empty_msg: 'Saat ini Anda belum memiliki data',
    courier_name: 'Nama kurir',
    accept_order: 'Terima Pesanan',
    reject_order: 'Tolak Pesanan',
    accept_cancel_order: 'Setuju Pembatalan',
    cancel_order: 'Batalkan Pesanan',
    cancel_request: 'Tolak Pembatalan',
    cancel_reason: 'Alasan Pembatalan',
    cancel_reason_sub: 'Alasan Batal',
    cancel_detail: 'Rincian pembatalan',
    select_empty_stock: 'Pilih stock yang habis',
    placeholder_cancel_detail: 'Jelaskan alasan pembatalan pesanan Anda',
    info_cancel_detail: 'Minimal {value} karakter',
    novalid_reason: 'Harap pilih alasan',
    accept_confirm_msg: 'Apakah Anda yakin ingin menerima pesanan ini?',
    transaction_discount: 'Diskon Transaksi',
    transaction_deposit: 'Deposit',
    transaction_loyalty: 'Loyalty',
    transaction_voucher: 'Voucher',
    transaction_shipping: 'Biaya Pengiriman',
    transaction_admin: 'Total Biaya Admin',
    discount_transaction: 'Diskon Transaksi dari',
    discount_shipping: 'Diskon Pengiriman dari',
    pickup_address: 'Alamat Pengambilan',
  },
  integration: {
    integration: 'Integrasi',
    name: 'Nama',
    channelManagement: 'Pengelolaan Saluran',
    warehouseManagement: 'Pergudangan',
    shippingManagement: 'Pengiriman',
    pointOfSales: 'Pusat Penjualan',
    paymentGateway: 'Jalur Pembayaran',
    accounting: 'Akuntansi',
    addChannel: 'Tambah Saluran Penjualan',
    addWarehouse: 'Tambah Gudang',
    addShipping: 'Tambah Pengiriman',
    addPointOfSales: 'Tambah titik Penjualan',
    addPaymentGateway: 'Tambah Jalur Pembayaran',
    addAccountingTool: 'Tambah Alat Akuntansi',
    startIntegration: 'Mulai Integrasi',
    confirmProduct: 'Konfirmasi Produk',
    editChannel: 'E d i t',
    deleteChannel: 'Putuskan Sambungan',
  },
  product: {
    title: 'Produk',
    listProduct: 'Daftar Produk Utama',
    priceArea: 'Harga per Area',
    searchBarPlaceholder: 'Pencarian',
    addProduct: 'Tambah Produk',
    editProduct: 'Edit Product',
    addPrice: 'Tambah Harga',
    clearFilter: 'Bersihkan filter',
    setfilter: 'Terapkan',
    date: {
      first: 'Awal',
      end: 'Akhir',
    },
    productStatus: {
      ACTIVE: 'Aktif',
      NON_ACTIVE: 'Tidak Aktif',
      INACTIVE: 'Tidak Aktif',
      DRAFT: 'Draft',
    },
    slug: 'Slug',
    addNewProduct: 'Tambah Produk Baru',
    uploadTemplate: 'Upload Template',
    cancel: 'Batal',
    next: 'Selanjutnya',
    selectClient: 'Pilih Klien',
    mass_change: 'Ubah Banyak',
    all_select: 'Semua',
    selected: 'Terpilih',
    delete: 'Hapus',
    export: 'Eksport',
    product_name: 'Nama Produk',
    categories: 'Kategori',
    varian: 'Varian',
    variant_name: 'Nama Varian',
    product_variant: 'Produk Varian',
    variant_desc:
      'Pilih salah satu untuk pengaturan tipe Produk Satuan, Produk Bundling, Produk Varian',
    range_price: 'Rentang Harga',
    channel: 'Channel', // Saluran
    status: 'Status',
    product_empty: 'Belum Ada Produk',
    have_no_product: 'Saat ini Anda belum memiliki produk.',
    product_details: 'Detail Produk',
    photo_video: 'Foto',
    product_type_and_price: 'Tipe Produk dan Harga',
    weight_product_shipping: 'Berat Produk dan Pengiriman',
    inventory: 'Inventory',
    product_information: 'Informasi Produk',
    brand: 'Merek Produk',
    item_condition: 'Kondisi Barang',
    specification: 'Spesifikasi',
    used_condition: 'Bekas',
    description: 'Deskripsi',
    short_description: 'Deskripsi Singkat',
    photo_title: 'Foto Produk',
    // 10MB
    foto_video_description:
      'Unggah foto dan varian yang dimiliki produk. Dimensi foto minimal {minWidth}x{minHeight} px. Ukuran file maksimal 1MB. Dengan format JPG, JPEG, PNG, BMP, GIF.',
    foto_video_description_master:
      'Unggah foto dan varian yang dimiliki produk. Dimensi foto minimal {minWidth}x{minHeight} px. Ukuran file maksimal 15MB. Dengan format JPG, JPEG, PNG, BMP, GIF.',
    video_title: 'Video Produk',
    video_description:
      'Unggah video produk Maksimal 30MB atau masukkan link video youtube dari produk',
    picture: 'Gambar',
    base_unit: 'Satuan Dasar',
    gift_size: 'Ukuran Bingkisan',
    unit: 'Satuan',
    weight_unit: 'Satuan Berat',
    weight: 'Berat',
    width: 'Lebar',
    height: 'Tinggi',
    length: 'Panjang',
    additional_unit: 'Satuan Tambahan',
    quantity_conversion: 'Konversi Kuantitas',
    add_new_unit: 'Tambah Satuan',
    add_warehouse: 'Tambah Gudang',
    location: 'Lokasi',
    make_main: 'Jadikan Utama',
    factory_sku: 'SKU Pabrik',
    set_wholesale_price: 'Atur Harga Grosir',
    add_wholesale_price: 'Tambah Harga Grosir',
    error_empty_variant: 'Nama Varian tidak boleh kosong',
    use_formula: 'Gunakan Kalkulasi Formula',
    totalItemPrice: 'Jumlah Harga Barang',
    productBundling: 'Produk Bundling',
    calc_price: 'Harga Kalkulasi Barang',
    import: 'Produk impor',
    importLabel: 'Asal Barang',
    'price-include-vat': 'Harga Termasuk PPN',
    'price-exclude-vat': 'Harga Belum Termasuk PPN',
    'price-no-vat': 'Harga Tidak Dikenakan PPN',
    write_something: 'Tuliskan Sesuatu',
    import_export: 'Impor / Ekspor',
    import_change_price: 'Impor Ubah Harga',
    export_product_data: 'Expor Data Produk',
    price_area: 'Harga Per Area',
    area: 'Area',
    customer_type: 'Tipe Pelanggan',
    price: 'Harga',
    effective_date: 'Tanggal Berlaku',
    add_price: 'Tambah Harga',
    stock_allocation: 'Alokasi Stok',
    allocated_stock: 'Stok Dialokasikan',
    catalog: 'Katalog Produk',
    product_category: 'Kategori Produk',
    category_name: 'Nama Kategori',
    new_product: 'Produk Terbaru',
    old_product: 'Produk Terlama',
    view_variant: 'Lihat Varian',
    close_variant: 'Tutup Varian',

    collection: {
      title: 'Produk Pilihan',
      type: {
        featured: 'Featured',
        bestseller: 'Best Seller',
        newarrival: 'New Arrival',
        special: "Special",
      },
      confirm: {
        delete: 'Apakah anda yakin ingin menghapus produk ini dari produk pilihan?',
      },
      'add-to-collection': 'Tambahkan ke produk pilihan',
      'type-label': 'Tipe',
    },
  },
  global_variable: {
    add: 'Tambah Variabel Global',
    empty_title: 'Tidak ada variabel global',
    empty_msg: 'Saat ini Anda belum memiliki variabel global',
  },
  formula_variable: {
    add: 'Tambah Variabel Formula',
    empty_title: 'Tidak ada variabel formula',
    empty_msg: 'Saat ini Anda belum memiliki variabel formula',
  },
  product_variable: {
    add: 'Tambah Variabel Produk',
    empty_title: 'Tidak ada variabel produk',
    empty_msg: 'Saat ini Anda belum memiliki variabel produk',
  },
  price_formula: {
    price_formula: 'Formula Harga',
    search: 'Cari',
    add_price_formula: 'Tambah Formula Harga',
    all: 'Semua',
    formula_name: 'Nama Formula',
    short_description: 'Deskripsi Singkat',
    cancel: 'Batal',
    rounding: 'Pembulatan',
    empty_title: 'Tidak ada formula harga',
    empty_msg: 'Saat ini Anda belum memiliki formula harga',
    error_add_variable: 'Harap masukkan variabel atau konstanta',
    error_add_operator: 'Harap masukkan operator',
    error_no_close: 'Harap tutup kurung',
    error_use_formula: 'Variabel ini digunakan diformula',
    error_close_bracket: 'Tutup kurung tidak sesuai',
    error_number_of_variable: 'Variabel atau konstanta minimal 2',
    error_get_global: 'Gagal mengambil data Variabel Global',
    error_get_formula: 'Gagal mengambil data Variabel Formula',
    error_get_product: 'Gagal mengambil data Variabel Produk',
  },
  price_setting: {
    label_variable: 'Nama Variabel',
    label_value: 'Value',
    label_formula: 'Nama Formula',
    label_description: 'Deskripsi Singkat',
    error_variable_name: 'Nama Variabel Tidak Boleh Kosong',
    error_max_variable_name: 'Nama Variabel Hanya Boleh 30 karakter',
    error_variable_name_pattern:
      'Nama Variabel Hanya Boleh Alfanumerik & garis bawah',
    error_max_formula: 'Nama Formula Hanya Boleh 30 karakter',
    error_formula_pattern: 'Nama Formula Hanya Boleh Alfanumerik & Underscore',
    error_formula_name: 'Nama Formula Tidak Boleh Kosong',
    error_value_empty: 'Value Tidak Boleh Kosong',
    error_description: 'Deskripsi Singkat Hanya Boleh 30 karakter',
    error_formula_same: 'Variabel tidak bisa lebih dari 1',
    error_formula_empty: 'Formula Tidak Boleh Kosong',
    confirm_delete_msg: 'Yakin ingin menghapus {type}',
    confirm_delete_info: '{type} tidak akan muncul kembali di dalam daftar',
    success_delete_msg: '{name} berhasil dihapus',
    cant_delete: 'Tidak Dapat Dihapus',
    used_msg: 'digunakan pada {type} berikut',
  },
  utils: {
    back: 'Kembali',
    choose: 'Pilih',
    filter: 'Filter',
    cancel: 'Batal',
    save: 'Simpan',
    edit: 'Ubah',
    add: 'Tambah',
    apply: 'Terapkan',
    variable: 'Variabel',
    search: 'Cari',
    insert: 'Masukkan',
    use: 'Gunakan',
    all: 'Semua',
    categories: 'Categories',
    channel: 'Saluran',
    date: 'Tanggal',
    example: 'Contoh',
    name: 'Nama',
    price: 'Harga',
    currency: 'Mata Uang',
    wrong: 'Ada yang salah',
    used: 'sudah digunakan',
    yes: 'Ya',
    no: 'Tidak',
    confirm_delete: 'Apakah Anda yakin ingin menghapus',
    change: 'Ubah',
    choose_a_tag: 'Pilih tag',
    choose_a_channel: 'Pilih channel',
    choose_a_warehouse: 'Pilih gudang',
    schedule: 'Jadwal',
    clearFilter: 'Bersihkan Filter',
    reload: 'Muat Ulang',
    export: 'Ekspor',
    status: 'Status',
    hour: 'Jam',
    not_found: 'Tidak ditemukan',
    amount: 'Jumlah',
    failed: 'Gagal',
    success: 'Berhasil',
    delete: 'Hapus',
    deleted: 'Terhapus',
    close: 'Tutup',
    formula: 'Formula',
    pagination_show_total: 'Menampilkan {perpage} dari {total} item',
    order_date: 'Tanggal Order',
    no_data: 'Tidak ada data',
  },
  no_valid: {
    required: 'Tidak Boleh Kosong',
    alphanum_underscore: 'Hanya Boleh Alfanumerik & garis bawah',
  },
  fulfillment: {
    totalFulfillment: 'Total Semua Fulfillment {value}',
    accept: 'Terima',
    reject: 'Batalkan',
    cancel: 'Batal',
    confirm: 'Konfirmasi',
    accept_msg: 'Apakah Anda yakin akan terima pesanan berikut?',
    reject_msg: 'Apakah Anda yakin akan membatalkan pesanan berikut?',
    pick_confirmed_msg: 'Apakah Anda yakin akan mengambil pesanan berikut?',
    pack_confirmed_msg: 'Apakah Anda yakin akan mengemas pesanan berikut?',
    packing_completed_msg:
      'Apakah Anda yakin akan mengirimkan pesanan berikut?',
    good_issued_msg: 'Apakah Anda yakin pesanan sudah siap dikirim?',
    shipped_msg: 'Apakah Anda yakin pesanan sudah terkirim?',
    accept_order: 'Ya, Terima Pesanan',
    reject_order: 'Ya, Batalkan Pesanan',
    btn_caption_accept_pick_confirmed: 'Ya, ambil pesanan',
    btn_caption_accept_pack_confirmed: 'Ya, kemas pesanan',
    btn_caption_packing_completed: 'Ya, kirimkan pesanan',
    btn_caption_good_issued: 'Ya, Pesanan siap dikirim',
    btn_caption_shipped: 'Ya, Pesanan terkirim',
    cancel_order: 'Batalkan Pesanan',
    reason_cancel: 'Alasan Pembatalan',
    description: 'Deskripsi',
    send: 'Kirim',
    min_char: 'Minimal 30 Karakter',
    search: 'Cari',
    all: 'Semua',
    order_list: 'Daftar Order',
    print_label: 'Cetak Label',
    setting_label: 'Pengaturan Label Pengiriman',
    paper_size: 'Ukuran Kertas',
    print_details: 'Cetak dengan detail barang',
    print_all_details: 'Cetak semua dengan detail barang',
    print_unboxing_video_label: 'Cetak dengan label wajib video unboxing',
    print_all_unboxing_video_label: 'Cetak semua dengan label wajib video unboxing',
    print_barcode_awb: 'Cetak dengan barcode awb',
    print_all_barcode_awb: 'Cetak semua dengan barcode awb',
    print_barcode_no_order: 'Cetak dengan barcode nomor order',
    print_all_barcode_no_order: 'Cetak semua dengan barcode nomor order',
    packed: 'Dikemas',
    packing_completed: 'Dikirim',
    inventory_status: 'Status Inventori',
    warehouse: 'Gudang',
    didntHaveData: 'Belum Memiliki Data',
    didntHaveDataCaption: 'Saat ini anda \n belum memiliki data',
    exportExcelFile: 'Export Excel File',
    exportHistoryPage: 'Halaman Riwayat Export',
    captionNotifFilterBeforeExport:
      'Lakukan Filter sebelum melakukan export \n dengan jangka waktu maksimal 3 bulan',
    order_date: 'Tgl. Order',
    order_id: 'Order ID',
    shipping_id: 'ID Pengiriman',
    business: 'Bisnis',
    channel: 'Channel',
    store: 'Nama Toko',
    totalItem: 'Total Item',
    inventory: 'Inventory',
    notification_success_accept_order: 'Success accept order',
    notification_success_update_order: 'Success update order',
    printLabel: 'Print Label',
    changeOrder: 'Ubah Pesanan',
    itemProduct: 'Item Produk',
    cancelItem: 'Batalkan Item',
    changeItem: 'Ubah Item',
    splitShipment: 'Pengiriman Terpisah',
    backToChangeOrder: 'Kembali Ubah Pesanan',
    booking_inventory: 'Inventory Booking',
    sla: 'SLA',
  },
  userManage: {
    search: 'Cari',
    add_user: 'Tambah Penguna',
    user_management: 'Manajemen Pengguna',
  },

  validations: {
    ...validation.messages,
    no_space: '{_field_} tidak boleh mengandung spasi',
  },
  businessClient: {
    client: 'Client',
    add_client: 'Tambah Client',
    edit_client: 'Edit Client',
    detail_client: 'Detail Client',
    id_business_user: 'ID Bisnis atau Username',
    information: 'Informasi',
    currency: 'Mata Uang',
    category: 'Kategori Bisnis',
    business_identity: 'Identitas Bisnis',
    business_name: 'Nama Bisnis',
    company_information: 'Informasi Perusahaan',
    company_name: 'Nama Perusahaan',
    tax_liability: 'Kewajiban Pajak',
    company_address: 'Alamat Perusahaan',
    warehouse: 'Gudang',
    contact: 'Kontak',
  },
  store_list_page: {
    undefined: '-',
    store_list: 'Daftar Toko',
    product_confirm: 'Konfirmasi Produk',
    confirmed: 'Tersinkronisasi',
    unconfirmed: 'Belum Tersinkronisasi',
    part_confirmed: 'Part Tersinkronisasi',
    mix_confirmed: 'Mix Tersinkronisasi',
    inactive: 'Tidak Aktif',
    store_code: 'Store Code',
    store_id: 'ID Toko',
    store_name: 'Nama Toko',
    sales_channel: 'Sales Channel',
    store_status: 'Status Toko',
    api_password: 'API Seller Key',
    auto_confirm: 'Konfirmasi Otomatis',
    authorization_time: 'Waktu Otorisasi',
    country: 'Negara',
    action: 'Tindakan',
    store_products: 'Nama Produk',
    variant: 'Varian',
    variant_count: 'Jumlah Varian',
    description: 'Keterangan',
    product_catalog_mapping: 'Mapping Katalog Produk',
    select_product: 'Pilih Produk',
    empty_title: 'Tidak ada data produk',
    empty_msg: 'Saat ini Anda belum memiliki data produk',
    search_placeholder: 'Cari toko atau kanal penjualan',
    add_store: 'Tambah Toko',
    connected: 'Terhubung',
    disconnected: 'Terputus',
    status: 'Status',
    all: 'Semua',
    product: 'Produk',
    change_product: 'Detail Produk',
    change_data: 'Ubah Data',
    deactivate_product: 'Nonaktifkan Produk',
    activate_product: 'Aktifkan Produk',
    search_products: 'Cari produk',
    product_name: 'Nama Produk',
    channel_products_catalog: 'Katalog Produk Channel',
    see_more: 'Lihat Lebih Banyak',
    modal_info:
      'Jika produk tidak terdapat pada produk master, lakukan tambah produk',
    modal_empty_msg: 'Produk tidak ada dalam produk master',
    categories: 'Kategori',
    range_price: 'Rentang Harga',
    program_name: 'Nama Program',
    voucher_name: 'Nama Voucher',
    voucher_type: 'Kode & Tipe Voucher',
    discount_rank: 'Tingkat Diskon',
    program_type: 'Tipe Program',
    periode: 'Periode',
    kuota: 'Kuota',
    active: 'Aktif',
    activate: 'Aktif',
    draft: 'Draft',
    notActive: 'Tidak Aktif',
    not_active: 'Tidak Aktif',
    view_detail: 'Lihat Detail',
    product_mapping: 'Mapping Produk',
    product_status: 'Status Produk',
    mapping_status: 'Status Mapping',
    rules: {
      store_name: 'Nama Toko harus diisi',
      store_id: 'ID Toko harus diisi',
      api_password: 'API Seller Key harus diisi',
      main_warehouse: 'Gudang Utama harus dipilih',
      channel_warehouse: 'Gudang Channel harus dipilih',
    },
  },
  export_history_page: {
    title: 'Riwayat Expor',
    total_caption: 'Total Semua Pesanan {total}',
    date: 'Tanggal',
    user: 'Pengguna',
    menu: 'Menu',
    file_export: 'Ekspor File',
    status: 'Status',
    try_again: 'Coba Lagi',
    empty_title: 'Tidak ada data',
    done: 'Selesai',
    failed: 'Gagal',
    all: 'Semua',
    product: 'Produk',
    order: 'Pesanan',
    fulfillment: 'Fulfillment',
    inventory: 'Inventory',
    formula: 'Formula',
  },
  inventory: {
    productName: 'Nama Produk',
    inventory: 'Gudang - Clien',
    firstPeriod: 'Periode Awal',
    initialStock: 'Stock Awal',
    lastPeriod: 'Periode Akhir',
    lastStock: 'Stock Akhir',
    baseUOM: 'Base UoM',
    date: 'Tanggal',
    changeType: 'Tipe Perubahan',
    changeStock: 'Perubahan Stok',
    period: 'Periode',
  },
  productAvailability: {
    search: 'Cari',
    productName: 'Nama Produk',
    vendorName: 'Nama Vendor',
    note: 'Catatan',
    productAvailability: 'Daftar Persediaan',
    goodsReceipt: 'Penerimaan Barang',
    gudang: 'Pilih Gudang',
    inbound: 'Buat Inbound',
    adjustment: 'Penyesuaian Stok',
    adjustmentType: 'Tipe Penyesuaian',
    allstatus: 'Semua Status',
    instock: 'Stok Tersedia',
    outofstock: 'Stok Habis',
    minusofstock: 'Stok Minus',
    movement: 'Riwayat Pemindahan',
    location: 'Lokasi',
    available: 'Tersedia',
    allocated: 'Teralokasi',
    grDate: 'Tanggal GR',
    grAdjustment: 'Tanggal Penyesuaian',
    warningGr: 'Pilih Gudang Untuk Membuat Penerimaan Barang',
    warningAdjustment: 'Pilih Gudang Untuk Membuat Penyesuaian Stok',
    reference: 'No. Referensi Dokumen',
    adjustmentNumber: 'No. Penyesuaian',
    warehouseName: 'Nama Gudang',
    warehouseStock: 'On Hand',
    inorder: 'On Order',
    safetyStock: 'Stok Pengaman',
    title: 'Nama Produk',
    sku: 'SKU',
    warehouseId: 'Nama Gudang',
    businessName: 'Nama Bisnis',
    availability: 'Stok Tersedia',
    allocatable: 'Stok dapat Dialokasikan',
    allocable: 'Dapat dialokasikan',
    onHand: 'On Hand',
    inOrder: 'Stok yang Dipesan',
    unit: 'Unit',
    updatedAt: 'Tanggal',
    IN_STOCK: 'In Stock',
    OUT_OF_STOCK: 'Out of Stock',
    MINUS_OF_STOCK: 'Minus Stock',
  },
  account: {
    profile: 'Profil Anda',
    fullname: 'Nama Lengkap',
    noHp: 'No. Handphone',
    setPassword: 'Gunakan Kata Sandi yang Kuat',
    setting: 'Pengaturan Akun',
    password: 'Ubah Kata Sandi',
  },
  warehouse: {
    list_warehouse: 'Daftar Gudang',
    export_data: 'Export Data',
    add_warehouse: 'Tambah Gudang',
    detail_warehouse: 'Detail Gudang',
    edit_warehouse: 'Ubah Gudang',
    name_warehouse: 'Nama Gudang',
    ext_code: 'Kode External',
    code_warehouse: 'Kode Gudang',
    category_warehouse: 'Kategori Gudang',
    address: 'Alamat',
    action: 'Aksi',
    information_warehouse: 'Informasi Gudang',
    address_warehouse: 'Alamat Gudang',
    integration_warehouse: 'Integrasi Gudang',
    connected_with: 'Terkoneksi dengan',
    update_warehouse: 'Ubah Gudang',
  },
  discount: {
    discount: 'Diskon',
    search: 'Cari Diskon',
    createDiscount: 'Tambah Diskon',
    latest: 'Data Terbaru',
    program_name: 'Nama Program',
    promotionName: 'Nama Program',
    discount_level: 'Tier Diskon',
    period: 'Periode',
    quota: 'Kuota',
    status: 'Status',
    all: 'Semua',
    waiting_approval: 'Menunggu Persetujuan',
    active: 'Aktif',
    not_active: 'Tidak Aktif',
    sort_by: 'Sortir',
    order_by: 'Urutan',
    newest: 'Terbaru',
    oldest: 'Terlama',
    basic_details: 'Rincian Dasar',
    promotion_target: 'Target Promosi',
    setting_promotion: 'Pengaturan Promosi',
    start_date: 'Waktu Mulai',
    end_date: 'Waktu Berakhir',
    promotion_type: 'Tipe Promosi',
    select_type: 'Pilih Tipe Promosi',
    select_level: 'Pilih Tier Diskon',
    national_program: 'Program Nasional',
    local_program: 'Program Lokal',
    distributor_program: 'Program Distributor',
    program_type: 'Tipe Program',
    select_program: 'Pilih Tipe Program',
    product_type: 'Tipe Produk',
    product_item: 'Item Produk',
    select_item: 'Pilih Item Produk',
    product_category: 'Kategori Produk',
    product_brand: 'Brand Produk',
    promotion_quota: 'Kuota Promosi',
    program_buy: 'Tipe Program & Pembelian',
    discount_format: 'Format Diskon',
    promotion_value: 'Nilai Promosi',
    promotion: 'Promosi',
    products: 'Produk',
    transaction: 'Transaksi',
    purchase_range: 'Rentang Pembelian',
    min_qty: 'Min. Qty',
    max_qty: 'Maks. Qty',
    min_trx: 'Min. Transaksi',
    max_trx: 'Maks. Transaksi',
    uom: 'UoM',
    promotion_format: 'Format Promosi',
    percentage: 'Presentase',
    currency: 'Mata Uang',
    fixed: 'Nominal',
    product: 'Ekstra Barang',
    product_name: 'Nama Produk',
    discount_max: 'Nilai Maks. Diskon',
    add_promotion: 'Tambah Promosi',
    voucher: 'Voucher',
    edit: 'Ubah Diskon',
    coin: 'Koin',
    coin_value: 'Nilai Koin',
    promo_cancellation: 'Pembatalan Promo',
    cancellation_description:
      'Dengan membatalkan Promo {value}, maka promo akan berstatus tidak aktif dan tidak dapat digunakan',
    choose_cancellation: 'Pilih alasan untuk membatalkan promo ini',
    write_reason: 'Tulis alasan lain disini',
    inactive: 'Tidak Aktif',
    createdAt: 'Tanggal Buat',
    promotion_criteria: 'Kriteria Promo',
    purchase_transaction: 'Jumlah Transaksi Pembelian',
    purchase_quantity: 'Jumlah Kuantitas Pembelian',
    promo_type: 'Tipe Kriteria Promo',
    select_promo_type: 'Pilih Tipe Kriteria Promo',
    range_promo: 'Kisaran',
    multiple_promo: 'Kelipatan',
    multiple_value: 'Nilai Kelipatan',
  },
  voucher: {
    voucher: 'Voucher',
    createvoucher: 'Tambah Voucher',
    latest: 'Data Terbaru',
    allstatus: 'Semua',
    waiting: 'Menunggu Persetujuan',
    active: 'Aktif',
    nonactive: 'Tidak Aktif',
    codeTypeVoucher: 'Kode & Tipe Voucher',
    create_voucher: 'Tambah Voucher',
    edit_voucher: 'Ubah Voucher',
    voucher_lists: 'Daftar Voucher',
    voucher_name: 'Nama Voucher',
    voucher_code: 'Kode Voucher',
    voucher_get: 'Periode Mendapatkan Voucher',
    voucher_usage: 'Periode Penggunaan Voucher',
    same_period: 'Sama dengan masa berlaku Voucher',
    voucher_combined: 'Voucher dapat dikombinasikan',
    edit: 'Ubah Voucher',
    promo_cancellation: 'Pembatalan Voucher',
    cancellation_description:
      'Dengan membatalkan Voucher {value}, maka voucher akan berstatus tidak aktif dan tidak dapat digunakan',
    choose_cancellation: 'Pilih alasan untuk membatalkan voucher ini',
    write_reason: 'Tulis alasan lain disini',
    inactive: 'Dibatalkan',
  },
  redeem: {
    redeem_product: 'Produk Redeem',
    search: 'Cari Produk',
    stock: 'Stok',
    redeem_count: 'Jumlah Redeem',
    minimum: 'Minimum',
    maximum: 'Maksimum',
    reset_filter: 'Bersihkan Filter',
    apply: 'Terapkan',
    category: 'Kategori',
    variant: 'Varian',
    status: 'Status',
  },
  addDiscount: {
    basic: 'Rincian Dasar',
    programName: 'Nama Program',
    startDate: 'Waktu Mulai',
    endDate: 'Waktu Berakhir',
    promotionType: 'Tipe Promosi',
    rate: 'Tingkat Diskon',
    target: 'Target Promosi',
    area: 'Area',
    distributor: 'Distributor',
    shopType: 'Tipe Toko',
    shop: 'Toko',
    programType: 'Tipe Program',
    productType: 'Tipe Produk',
    settingPromo: 'Pengaturan Promosi',
    itemProduct: 'Item Produk',
    kuota: 'Kuota Promosi',
    typeBuy: 'Tipe Program & Pembelian',
    format: 'Format Diskon',
    nilaiPromosi: 'Nilai Promosi',
    promotion: 'Promosi',
    addPromotion: 'Tambah Promosi',
    purchaseRange: 'Rentang Pembelian',
    promotionFormat: 'Format Promosi',
  },
  addVoucher: {
    basic: 'Rincian Dasar',
    voucherName: 'Nama Voucher',
    voucherCode: 'Kode Voucher',
    startDate: 'Waktu Mulai',
    endDate: 'Waktu Berakhir',
    voucherType: 'Tipe Voucher',
    rate: 'Tingkat Diskon',
    target: 'Target Promosi',
    area: 'Area',
    distributor: 'Distributor',
    shopType: 'Tipe Toko',
    shop: 'Toko',
    cutType: 'Tipe Potongan',
    productType: 'Tipe Produk',
    settingVoucher: 'Pengaturan Voucher',
    itemProduct: 'Item Produk',
    kuota: 'Kuota Promosi',
    typeBuy: 'Tipe Program & Pembelian',
    format: 'Format Diskon',
    nilaiPromosi: 'Nilai Promosi',
    periode: 'Periode',
    addPromotion: 'Tambah Promosi',
    purchaseRange: 'Rentang Pembelian',
    promotionFormat: 'Format Promosi',
    usageQuota: 'Kuota Pemakaian',
    minimumReq: 'Persyaratan Minimum',
  },
  finance: {
    list_transfer: 'Daftar Bukti Transfer',
    search: 'Cari Bukti Transfer',
    pay_number: 'No. Pembayaran',
    method: 'Metode',
    total_bill: 'Total Tagihan',
    rest_of_bill: 'Sisa Tagihan',
    payment_deadline: 'Tanggal Request',
    payment_status: 'Status Pembayaran',
    invoice_number: 'No. Invoice',
    customer_name: 'Nama Pelanggan',
    order_number: 'No. Order',
    payment_confirm: 'Konfirmasi',
    payment_reject: 'Tolak Bukti Transfer',
    transaction_detail: 'Detail Transaksi',
    order_id: 'ID Order',
    transaction_date: 'Tanggal Transaksi',
    bill_amount: 'Jumlah Tagihan',
    discount_use: 'Pemakaian Diskon',
    point_use: 'Pemakaian Poin',
    payment_date: 'Tanggal Bayar',
    payment_type: 'Tipe Pembayaran',
    bank_name: 'Nama Bank',
    account_number: 'Nomor Rekening',
    transfer_evidence: 'Bukti Transfer',
    input_payment: 'Nominal Pembayaran',
    mistake: 'Kesalahan',
    select_mistake: 'Pilih Kesalahan',
    expired_date: 'Tanggal Expired',
    payment_amount: 'Nominal Pembayaran',
    need_confirmation: 'Perlu Konfirmasi',
    confirmed: 'Sudah di Konfirmasi',
    waiting: 'Perlu Konfirmasi',
    cancelled: 'Dibatalkan',
    rejected: 'Ditolak',
    outstanding: 'Outstanding',
    confirm_transfer: 'Konfirmasi Bukti Transfer',
    payment_history: 'Riwayat Pembayaran',
    create_payment: 'Buat Pembayaran',
    total_pay: 'Total Bayar',
    pay_date: 'Tanggal Bayar',
    pay_method: 'Metode Pembayaran',
    pay_name: 'Nama Pembayaran',
    order_detail: 'Detail Order',
    create: 'Buat',
    account_name: 'Nama Rekening',
    payout_bank_name: 'Payout Bank Name',
    payout_account_number: 'Payout Account Number',
    payout_account_name: 'Payout Account Name',
  },
  withdrawal: {
    distributor_wallet: 'Wallet Distributor',
    withdraw_wallet: 'Tarik Wallet',
    history_wallet: 'Riwayat Wallet',
    estimated_balance: 'Estimasi Total Saldo berikutnya',
    disclaimer_balance: 'Nilai total saldo diatas, belum termasuk biaya-biaya lain yang berlaku',
    total_balance: 'Total Saldo',
    withdrawal_history: 'Riwayat Penarikan',
    request_date: 'Tanggal Permintaan',
    nominal: 'Nominal',
    date: 'Tanggal',
    status: 'Status',
    request_id: 'ID Request',
    bank_details: 'Detail Bank',
    bank_name: 'Nama Bank',
    bank_account: 'Akun Bank',
    account_number: 'Nomor Rekening',
    account_name: 'Nama Akun',
    benificiary_name: 'Nama Penerima',
    processing_date: 'Tanggal Proses',
    withdrawal_form: 'Form Request Penarikan',
    withdrawal_amount: 'Jumlah Penarikan',
    minimum_withdrawal: 'Minimal penarikan',
    withdrawal_fee: 'Biaya penarikan',
    data_correct: 'Semua data sudah sesuai',
    select_bank: 'Pilih Bank',
    all: 'Semua',
    draft: 'Dalam Pengajuan',
    confirmed: 'Disetujui',
    canceled: 'Dibatalkan',
    completed: 'Selesai',
    approved: 'Disetujui',
    rejected: 'Ditolak',
    processed: 'Dalam Proses',
    failed: 'Gagal',
    terms1: 'Maksimal penarikan {max_frequency}x dalam {frequency_time_value} {frequency_time_unit}',
    terms2: 'Proses transfer dilakukan maksimal 2x24 jam setelah request dikirim',
    terms3: 'Minimum penarikan {min_amount} dan maksimum penarikan {max_amount}',
    text_min_amount: 'Minimum penarikan {min_amount}',
    text_max_amount: 'Maksimum penarikan {max_amount}',
    month: 'bulan',
    week: 'minggu',
    day: 'hari',
  },
  helpdesk_page: {
    title: 'Ada yang bisa kita bantu?',
    info: `Apabila Anda memerlukan bantuan silahkan hubungi CS Kino Siap \ndi nomor handphone atau alamat email yang tertera.`,
  },
  error: {
    CL_NOT_ELIGIBLE: 'Kredit limit tidak memenuhi syarat',
    CL_INSUFFICIENT: 'sisa kredit limit tidak mencukupi',
    CL_EXCEED_MULTIPLEBOND: 'Kesempatan untuk hutang sudah habis',
    CL_OVERDUE: 'Ada transaksi yang sudah jatuh tempo',
    WP_NOT_FOUND: 'Tidak ada transaksi yang menunggu pembayaran',
  },
  tiktok: {
    categoryMaster: 'Kategori Produk Utama',
    categoryTiktok: 'Kategori Produk Tiktok',
    categoryShopee: 'Kategori Produk Shopee',
    description:
      'Unggah size chart yang dimiliki produk. Dimensi foto minimal {minWidth}x{minHeight} px. Ukuran file maksimal 1MB. Dengan format JPG, JPEG, PNG, BMP, GIF.',
    garansi: 'Masa Garansi',
    policy: 'Kebijakan Garansi',
  },
  adjustment: {
    stock: 'Penyesuaian Stok',
    create: 'Buat Penyesuaian',
    id: 'Nomor Penyesuaian',
    date: 'Tanggal Penyesuaian',
    type: 'Tipe Penyesuaian',
    search: 'Cari Penyesuaian',
    detail: 'Detail Penyesuaian',
    chooseType: 'Pilih Tipe Penyesuaian',
    note: 'Catatan',
    detailItem: 'Detail Item Produk',
    addItem: 'Tambah Item',
    stockAvailable: 'Stok Tersedia',
    stockChanges: 'Perubahan Stok',
    stockAfterChange: 'Stok Setelah Perubahan',
    adjust: 'Sesuaikan',
    sync: 'Sinkronisasi Stok',
    choose_sync_warehouse: 'Pilih gudang yang akan disinkronkan stoknya',
    all_warehouse: 'Semua Gudang',
    is_syncing: 'Sedang mensinkronisasi',
    last_sync: 'Sinkronisasi terakhir',
  },
  channel: {
    category: {
      default: 'Kategori Channel',
      tiktok: 'Kategori Produk Tiktok',
      tiktok_id: 'Kategori Produk Tiktok',
      tokopedia_id: 'Kategori Produk Tokopedia',
      shopee_id: 'Kategori Produk Shopee',
    },
    product: {
      tiktok: 'Produk Tiktok',
      tiktok_id: 'Produk Tiktok',
      tokopedia_id: 'Produk Tokopedia',
      shopee_id: 'Produk Shopee',
    },
  },
  sales: {
    date_from: 'Periode',
    business_id_sales: 'Bisnis',
    sales_channel_id: 'Channel',
    warehouse_id: 'Gudang',
    customer_name: 'Nama Pelanggan',
    courier_name: 'Nama Kurir',
  },
  po: {
    cancel: 'Batalkan PO',
    cancel_confirm: 'Ya, batalkan PO',
    cancel_success: 'PO berhasil dibatalkan',
    cancel_description: 'Apakah Anda yakin akan membatalkan PO ini?',
  },
}

export const localeAntd = antd
export default messages
