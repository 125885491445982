import apiClient from '@/services/axios'

export const exportInventory = (params) => {
  return apiClient({
    method: 'get',
    url: 'inventoryv2/query/export-inventory',
    params,
  })
}

export const checkRequestExportInventory = ({ request_id, params }) => {
  return apiClient({
    method: 'get',
    url: `inventoryv2/query/request-status/${request_id}`,
    params,
  })
}

export const getInventoryList = (params) => {
  return apiClient({
    method: 'get',
    url: 'inventoryv2/query/list',
    params,
  })
}

export const getInventoryListV2 = ({ business_id, workspace_id, product_id, catalog_id }) => {
  return apiClient({
    method: 'get',
    url: 'inventoryv2/query/inventory',
    headers: {
      'Business-Id': business_id,
      'Workspace-Id': workspace_id,
    },
    params: {
      product_id: product_id,
      business_id: business_id,
      catalog_id: catalog_id,
    },
  })
}

export const getInventoryByProductId = ({ product_id, params }) => {
  return apiClient({
    method: 'get',
    url: `/inventory/inventory/${product_id}`,
    headers: {
      'Business-Id': params.business_id,
    },
    params,
  })
}

export const getAllocationStock = (params) => {
  return apiClient({
    method: 'get',
    url: '/inventory-channel/inventory-channel/detail',
    params,
  })
}

export const getInventories = (params) => {
  return apiClient({
    method: 'get',
    url: '/inventory/list',
    params,
  })
}

export const getInboundList = (params) => {
  return apiClient({
    method: 'get',
    url: '/inventory/movement',
    params,
  })
}

export const getStock = (params) => {
  return apiClient({
    method: 'get',
    url: '/inventory/inventory',
    params,
  })
}

export const getUom = ({ id, params }) => {
  return apiClient({
    method: 'get',
    url: `/inventory/productUnit/${id}`,
    headers: {
      'Business-Id': params.business_id,
    },
    params,
  })
}

export const getStockAdjustment = (params) => {
  return apiClient({
    method: 'get',
    url: '/inventoryv2/query/adjustment/list',
    params,
  })
}

export const checkSyncStock = ({ business_id, params }) => {
  return apiClient({
    method: 'get',
    url: '/inventoryv2/query/cek-sync-stock/',
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}

export const getDetailStockMovementBySKU = (params) => {
  return apiClient({
    method: 'get',
    url: 'inventoryv2/query/detail',
    params,
  })
}

export const checkSyncStockStatus = (request_id) => {
  return apiClient({
    method: 'get',
    url: `inventory-channel/inventory-channel/cek-sync-stock/${request_id}`,
  })
}

export const getDashboardSkuAndOos = (params) => {
  return apiClient({
    method: 'get',
    url: 'inventoryv2/query/dash/sku',
    params,
  })
}

export const getCatalogListGR = (params) => {
  return apiClient({
    method: 'get',
    url: 'inventoryv2/query/catalogs',
    headers: {
      'Business-Id': params.business_id,
    },
    params,
  })
}

export const getCatalogListBySKU = (params) => {
  return apiClient({
    method: 'get',
    url: 'inventoryv2/query/sku',
    headers: {
      'Business-Id': params.business_id,
    },
    params,
  })
}

export const getInventoryMovement = ({ type, params }) => {
  return apiClient({
    method: 'get',
    url: `/inventoryv2/query/movement/${type}`,
    params,
  })
}

export const addAllocationStock = (data) => {
  return apiClient({
    method: 'post',
    url: '/inventory-channel/inventory-channel/stock-allocation',
    data,
  })
}

export const addWarehouse = ({ business_id, data }) => {
  return apiClient({
    method: 'post',
    url: '/inventory-cmd/',
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const addInbound = (data) => {
  return apiClient({
    method: 'post',
    url: '/inventory-cmd/inbound',
    data,
  })
}

export const syncStock = ({ business_id, params }) => {
  return apiClient({
    method: 'post',
    url: '/inventoryv2/cmd/sync-stock/',
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}

export const createAdjustment = ({ data }) => {
  return apiClient({
    method: 'post',
    url: 'inventoryv2/cmd/adjustment/create',
    data,
  })
}

export const republishChannelStock = (data) => {
  return apiClient({
    method: 'post',
    url: 'inventory-channel/inventory-channel/republish-stock-multiple',
    data,
  })
}

export const saveProductInventory = (data) => {
  return apiClient({
    method: 'post',
    url: `/inventory-cmd/product_unit`,
    data,
    params: {
      business_id: data.business_id,
    },
  })
}

export const editAdjument = (data) => {
  return apiClient({
    method: 'put',
    url: '/inventory-cmd/adjustment',
    data,
  })
}

export const addProductToWarehouse = (data) => {
  return apiClient({
    method: 'post',
    url: `/inventory-cmd/internal/inventory/added-warehouse`,
    data,
  })
}

export const checkRequestStatusAddProductToWarehouse = ({ params, id_request }) => {
  return apiClient({
    method: 'get',
    url: `/inventory-cmd/request-status/${id_request}`,
    params,
  })
}

