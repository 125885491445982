var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-sub-menu',_vm._g(_vm._b({key:_vm.menuInfo.key,class:{
    'selected-parent-menu': _vm.selectedMenu,
  }},'a-sub-menu',_vm.$props,false),_vm.$listeners),[(!_vm.settings.isMenuCollapsed)?_c('span',{staticClass:"d-flex align-items-center",attrs:{"slot":"title"},slot:"title"},[(_vm.menuInfo.sales_channel?.code)?_c('img',{staticClass:"pr-2",class:{ 'not-channel': !_vm.isChannel },attrs:{"src":_vm.isChannel && _vm.menuInfo?.notes === 'tiktok_tokopedia' 
        ? require(`@/assets/store/${_vm.menuInfo?.notes}.png`) 
        : _vm.isChannel && _vm.menuInfo?.notes !== 'tiktok_tokopedia' 
          ? require(`@/assets/store/${_vm.menuInfo.sales_channel?.code.toLowerCase()}.png`) 
          : require('@/assets/images/logo_small.png'),"alt":""}}):_vm._e(),(!_vm.menuInfo.sales_channel?.code)?_c('a-icon',{staticClass:"pr-2",staticStyle:{"font-size":"18px"},attrs:{"type":_vm.setIcon(_vm.menuInfo.icon)}}):_vm._e(),(_vm.titleMenu.length > 17)?_c('a-tooltip',{attrs:{"placement":"bottomLeft"}},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.titleMenu)+" ")]),_vm._v(" "+_vm._s(_vm.slicingWording(_vm.titleMenu))+" ")],2):_c('div',[_vm._v(_vm._s(_vm.titleMenu))])],1):_vm._e(),_vm._l((_vm.menuInfo.children),function(item){return [(!item.children && !item.divider)?_c('item',{key:item.key,attrs:{"menu-info":item}}):_vm._e(),(item.children)?_c('sub-menu',{key:item.key,attrs:{"menu-info":item}}):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }