import { types as MutationTypes } from './mutations'
import { getBusiness, listChannel } from '@/api/business'

export const GETBUSINESS = async ({ commit }, params) => {
  let data
  try {
    commit(MutationTypes.SET_BUSINESS_STORE, {
      loading: true,
    })

    const { data: response } = await getBusiness(params)
    data = response.data
    return Promise.resolve(response)
  } catch (error) {
    data = {}
    return Promise.reject(error)
  } finally {
    const findIndex = data.findIndex((item) => item.business_id === params.business_id)
    commit(MutationTypes.SET_BUSINESS_STORE, {
      loading: false,
      detailBusiness: findIndex > -1 ? data[findIndex] : {},
      businesses: data,
    })
  }
}

export const LISTCHANNEL = async ({ commit, rootState }, { business_id }) => {
  try {
    commit(MutationTypes.SET_BUSINESS_STORE, {
      fetchingListChannel: true,
    })
    const { data: response } = await listChannel({ business_id, limit: 500, status: 'connected' })
    const channels = response?.data?.length
      ? response.data.map(channel => ({
          ...channel,
          title: channel?.title || channel?.sales_channel?.name,
          notes: channel.sales_channel?.code === 'tiktok_tokopedia'
            ? channel.sales_channel.code
            : channel?.notes,
          sales_channel: {
            ...channel.sales_channel,
            code: channel.sales_channel?.code === 'tiktok_tokopedia'
              ? 'tiktok'
              : channel.sales_channel.code,
          },
        }))
      : []
    commit(MutationTypes.SET_BUSINESS_STORE, {
      listChannels: channels,
    })
    return Promise.resolve({
      data: { ...response, data: channels },
      dataState: rootState,
    })
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_BUSINESS_STORE, {
      fetchingListChannel: false,
    })
  }
}
