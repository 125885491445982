import apiClient from '@/services/axios'

export const getBusinessList = (params) => {
  return apiClient({
    method: 'get',
    url: '/business/query/',
    params,
  })
}

export const getBusinesses = (params) => {
  return apiClient({
    method: 'get',
    url: '/business/query/business',
    params,
  })
}

export const getBusiness = (params = {}) => {
  return apiClient({
    method: 'get',
    url: '/business/query/business/find',
    params,
  })
}

export const getCategoryBusiness = () => {
  return apiClient({
    method: 'get',
    url: '/business/query/categories',
  })
}

export const listChannel = ({ type = undefined, ...params }) => {
  const path = type ? `/${type}` : ''
  return apiClient({
    method: 'get',
    url: `/business/query/channel${path}`,
    params,
  })
}

export const getChannelsByBusinessList = (params) => {
  return apiClient({
    method: 'get',
    url: '/business/query/channel/list-by-businesses',
    params,
  })
}

export const getBusinessChannel = (business_id) => {
  return apiClient({
    method: 'get',
    url: '/business/query/channel/list-channels',
    headers: {
      'Business-Id': business_id,
    },
    params: { business_id },
  })
}

export const getChannelStatus = (id) => {
  return apiClient({
    method: 'get',
    url: `/business/query/channel/${id}`,
  })
}

export const getBusinessMetaData = ({ business_id }) => {
  return apiClient({
    method: 'get',
    url: '/business/query/internal/meta',
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const addBusiness = ({ data, editPage }) => {
  return apiClient({
    method: editPage ? 'put' : 'post',
    url: editPage ? '/business/cmd/update' : '/business/cmd/save',
    data,
  })
}

export const crudChannel = ({ id, data = undefined }) => {
  return apiClient({
    method: 'put',
    url: `business/cmd/channel/${id}`,
    data,
  })
}

export const deleteBusiness = (uid) => {
  return apiClient({
    method: 'delete',
    url: `/business/cmd/${uid}`,
  })
}
