import { computed } from 'vue'
import store from '@/store'
import { i18n } from '@/i18n'

export default ({ menuInfo }) => {
  const { state } = store

  /* Data From Vuex */
  const settings = state.settings
  const store_url = state.user.store_url;

  /* Translate Menu Item */
  const titleMenu = computed(() => { 
    if(menuInfo.lang) {
      if (
        i18n.t(`sideBar.${menuInfo.lang}`) ===
        `sideBar.${menuInfo.lang}`
      ) {
        return menuInfo.title
      }

      return i18n.t(`sideBar.${menuInfo.lang}`)
    }
    return menuInfo.title
  })

  /* Active Menu */
  const selectedMenu = computed(() => {
    const value = settings.selectedMenu.find(
      (item) => item === menuInfo.key,
    )
    return value
  });

  /* Opened Sub Menu */
  const openedMenu = computed(() => {
    return settings.openedMenu.find(
      (item) => item === menuInfo.key,
    )
  });

  const getQueryParam = (param) => {
    let filtered = {}
    const allowed = ['workspace_id', 'business_id', 'warehouse_id']
    
    for(const prop in param) {
      if(allowed.includes(prop)) filtered = { ...filtered, [prop]: param[prop] }
    }

    return filtered
  }

  const slicingWording = (value) => {
    if (value.length <= 17) {
      return value
    }
      return `${value.slice(0, 17)}...`
  }

  const setIcon = (value) => {
    switch(value) {
      case 'home':
        return 'home'
      case 'order':
        return 'shopping'
      case 'product-catalogue':
        return 'gold'
      case 'inventory':
        return 'database'
      case 'export-history':
        return 'export'
      case 'store':
        return 'shop'
      case 'user':
        return 'user'
      case 'warehouse':
        return 'bank'
      case 'account':
        return 'unlock'
      case 'profile':
        return 'profile'
      default:
        return 'file'
    }
  }
    
  return {
    settings,
    titleMenu,
    selectedMenu,
    openedMenu,
    store_url,
    getQueryParam,
    slicingWording,
    setIcon,
  }
}
