let arrayMenu = []

export const filterMenu = (array, matched) => {
  let result
  let length = array.length
  for(let i = 0; i < length; i++) {
    if(array[i].key.toLowerCase() === matched.toLowerCase()) {
      return array[i]
    } else if(array[i].children) {
      result = filterMenu(array[i].children, matched)
      if(result) {
        arrayMenu.unshift(array[i].key)
        return result
      }
    }
  }
  return result
}

export const getSelectedMenu = (menus = [], string) => {
  const filter = filterMenu(menus, string)
  if(filter?.key) arrayMenu.push(filter.key)
  const result = arrayMenu
  arrayMenu = []
  return result
}

export const replaceMenuData = (dataArray, key_parent, item, type) => {
  const result = dataArray?.map(val => {
    const children =
      val?.children &&
      replaceMenuData(val?.children, val.key, item, 'children')

    let routeName = type === 'parent' ? `${val.key}.id` : `${key_parent}.${val.key}`
    let key = [val.key, item.id].join('-')

    if (item.sales_channel?.id === 53 && (['product-master', 'product-list'].includes(val.key))) {
      routeName = type === 'parent' ? `web-platform-${val.key}.id` : `web-platform-product-channel.product-master`
      key = ['web-platform', key].join('-')
    }
    let paramsId = item.id
    return {
      ...val,
      key: key,
      url: {
        name: routeName,
        params: {
          id: paramsId,
          channelId: paramsId,
        },
        query: {
          sales_channel_id: item.sales_channel?.id,
          channel_code: item.sales_channel?.code,
        },
      },
      children: children,
    }
  })

  /* If not dropshiper or status is Connected */
  if(item.sales_channel?.id !== 55 && item.status === "CONNECTED") {
    return result
  }
}
