import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import integration from './integration'
import settings from './settings'
import business from './business'
// import dashboard from './dashboard'
import products from './products'
import channel from './channel'
// import price from './price'
// import sales from './sales'
// import fulfillment from './fulfillment'
import warehouse from './warehouse'
// import shipping from './shipping'
// import invoice from './invoice'
// import promotion from './promotion'
// import distributor from './distributor'
// import customerChannel from './customer-channel'
import productChannel from './product-channel'
// import finance from './finance'
// import meta from './meta'
import marketplace from './marketplace'
import exportData from './export-data'

/* === Web platform channel === */
import web_platform_channel from './web-platform-channel'
/* === End Web platform channel === */

// import retur from './retur'
import router from '@/router'
import { getSelectedMenu, replaceMenuData } from '@/utils/recursiveMenu'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    user,
    integration,
    settings,
    business,
    // dashboard,
    products,
    channel,
    // price,
    // sales,
    // fulfillment,
    warehouse,
    // shipping,
    // invoice,
    // promotion,
    // distributor,
    // customerChannel,
    productChannel,
    // finance,
    // retur,
    // meta,
    marketplace,
    exportData,

    /* === Web platform channel === */
    web_platform_channel,
    /* === End Web platform channel === */
  },
  state: {},
  getters: {
    activeBusinessId() {
      return () => router.currentRoute.query.business_id
    },
    channelData: state => valSearch => {
      const menu = state.user.userMenu
      let dataMenuChannelDT
      let dataMenuChannelStore
      let dataMenuChannelNotDT
      let dataMenuChannelOffline
      let menuChannel = []

      for(let i = menu.length - 1; i >= 0; i--) {
        if(menu[i].key === 'group3') {
          const channel = menu[i].children || []
          for(let j = channel.length - 1; j >= 0; j--) {
            if(channel[j].key === 'distributorship') {
              dataMenuChannelDT = channel[j].children
            } else if(channel[j].key === 'store') {
              dataMenuChannelStore = channel[j].children
            } else if(channel[j].key === 'ecommerce') {
              dataMenuChannelNotDT = channel[j].children
            } else {
              dataMenuChannelOffline = channel[j].children
            }
          }
          i = 0
        }
      }
      
      const channels = state.business.listChannels
      for(let i = channels.length - 1; i >= 0; i--) {
        if(
          channels[i].title && 
          channels[i].title.toLowerCase().includes(valSearch.toLowerCase()) && 
          channels[i].status === "CONNECTED" && 
          channels[i].sales_channel?.id !== 55
        ) {
          const channelCode = channels[i].sales_channel?.code || channels[i].salesChannel?.code
          const menuMap = {
            distributor: dataMenuChannelDT,
            store: dataMenuChannelStore,
            default: dataMenuChannelNotDT,
            offline: dataMenuChannelOffline,
            b2b_offline: dataMenuChannelOffline,
            // FIX ME: untuk sementara manual order on blibli dan zalora
            // blibli_id: dataMenuChannelOffline,
            zalora_id: dataMenuChannelOffline,
            external_web: dataMenuChannelOffline,
            whatsapp_id: dataMenuChannelOffline,
            shopify_id: dataMenuChannelOffline,
          }
          const dataMenuChannel = menuMap[channelCode] || menuMap.default
          const newDataMenuChannel = channels[i].notes?.toLowerCase() === 'cm'
            ? dataMenuChannel?.filter(menuChannel => menuChannel.key.includes('sales'))
            : dataMenuChannel
          const children = replaceMenuData(
            newDataMenuChannel,
            '',
            channels[i],
            'parent',
          )
          menuChannel.unshift({
            ...channels[i],
            key: `store-${channels[i].id}`,
            type: 'store',
            icon: 'store',
            children: children,
          })
        }
      }
      const filteredDistriRedeem = menuChannel.filter((item) => item.sales_channel.code !== 'distributor_redeem')
      return filteredDistriRedeem
    },
    setSelectedMenu: (state, getters) => {
      const { meta, params, path } = router.history.current
      const id = params.id
      let key = !path.match(/\d/)
        ? meta.key
        : meta.key.replace(':id', id)
      
      if (
        !path.match(/\d/) &&
        ['channel-fulfillment', 'channel-sales', 'channel-product', 'channel-promotion', 'channel-customer'].includes(key)
      ) {
        const navigationData = state.user.channelNavigation.find(
          f => parseInt(f.id) === parseInt(id),
        )
        const titleLowerCase = (
          (navigationData && navigationData.title) ||
          ''
        ).toLowerCase()
        key = `${key}-${titleLowerCase}`
      }

      const data = !path.match(/\d/)
        ? getters['user/menuData']
        : getters.channelData('')
      let menu = getSelectedMenu(data, key)

      if (!path.match(/\d/) && !menu.length) {
        key = path.split('/')?.[1]
        menu = getSelectedMenu(data, key).slice(0, 3)
      }
      return menu
    },
  },
  mutations: {},
})

export const useStore = () => {
  return store
}

export default store
