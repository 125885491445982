import apiClient from '@/services/axios'

export const getLazadaWarehouseChannelList = ({ channel_id }) => {
  return apiClient({
    method: 'get',
    url: `/channel/lazada_id/integration/${channel_id}/warehouse`,
  })
}

export const adjustLazadaMappingWarehouseChannel = ({ channel_id, business_id, data }) => {
  return apiClient({
    method: 'post',
    url: `/channel/lazada_id/integration/${channel_id}/warehouse`,
    data,
    headers: {
      'Business-Id': business_id,
    },
  })
}
